import React, { useState, useEffect } from 'react';

import Getaquote from '../../../components/get-started-today-button';
import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='PENNSYLVANIA INSURANCE AVAILABLE' activeCard='pennsylvania' image={'/Pennsylvania_BG.jpeg'}>
          <p>
            Are you looking for individual health insurance plans in Pennsylvania? Maybe your small business needs a reliable group insurance plan. Whatever
            your needs, Apollo Insurance in Pennsylvania can help. Our agents start every meeting by discussing your needs to understand how you want to use
            your health insurance. We strive for a better understanding of your preferred doctors, prescriptions, and visit frequency. This helps us select the
            best plan from the best providers in Pennsylvania, such as Ambetter, Aliera, United Healthcare, and National General.
          </p>
          <p>
            Trust the Apollo Insurance professionals to find affordable health insurance for all your needs. Our Pennsylvania health insurance agents are
            experts in educating our clients in the basics of the health insurance industry and helping them overcome the new challenges that come with a new
            year. Let us assist you with your healthcare needs! Call us today at (913) 279-0077 for a quote.
          </p>
          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance shifting to the consumer, gap insurance plans have become the most important and popular plan we sell
            today. Our expert advisors can walk you through your options to find the best fit for your situation.
          </p>

          <span>Understanding HSA, FSA, and HRA</span>

          <p>
            Trust Apollo Insurance experts with your health insurance. We are experienced in working within the Nevada health industry’s difficult parameters to
            meet your needs. You shouldn’t have to struggle through the fundamentals of health insurance on your own – trust Apollo Insurance to help you find
            the provider that’s right for you! Contact us today for a quote!
          </p>

          <p>Apollo Insurance can answer all of your health insurance questions. We have agents standing by waiting for your call.</p>
        </Content>
      </div>
    </Layout>
  );
};
